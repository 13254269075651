import React from 'react';
import '../../index.css'; // Import your CSS file

const Card = ({ children, className = '', style }) => {
  return (
    <div className={`card ${className}`} style={style}>
      {children}
    </div>
  );
};

export default Card;
